import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createConnection, editConnection } from '@sync-spider/sdk';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
	IconButton,
	Icon,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getCommonSDK } from '../../core/constants';
import { useDebounce } from '../../utils/hooks';

const PER_PAGE = 5;

function getManyConnections(options) {
	const { perPage = PER_PAGE, page, search } = options || {};
	const baseURL = `${window.basePath}/connection`;

	const queryParams = new URLSearchParams({
		...(perPage && { per_page: perPage }),
		...(page && { page }),
		...(search && { search }),
	}).toString();

	const url = `${baseURL}?${queryParams}`;

	return axios.get(url).then((response) => {
		return response.data;
	});
}

const Connections = () => {
	const [connections, setConnections] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const debouncedPage = useDebounce(page, 500);
	const [total, setTotal] = useState(0);
	const [connectionsSearchTerm, setConnectionsSearchTerm] = useState('');
	const debouncedConnectionsSearchTerm = useDebounce(connectionsSearchTerm);

	const [searchTerm, setSearchTerm] = useState('');
	const [{ modules, filteredModules }, setModules] = useState({ modules: [], filteredModules: [] });
	const [isModuleSelectDialogOpen, setIsModuleSelectDialogOpen] = useState(false);

	const columns = [
		{ field: 'name', headerName: 'Name', sortable: false, flex: 1 },
		{ field: 'moduleName', headerName: 'Module Name', sortable: false, flex: 1 },
		{ field: 'status', headerName: 'Status', sortable: false, flex: 1 },
		{
			field: 'id',
			headerName: 'Actions',
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<IconButton component="span" onClick={handleEditClick(params.value)}>
							<Icon>edit</Icon>
						</IconButton>
						<IconButton color="error" component="span" onClick={handleDeleteClick(params.value)}>
							<Icon>delete</Icon>
						</IconButton>
					</>
				);
			},
		},
	];

	const loadConnections = () => {
		setLoading(true);
		getManyConnections({ page, search: connectionsSearchTerm })
			.then(({ data, meta }) => {
				setConnections(data);
				setTotal(meta.pagination.total);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(loadConnections, [debouncedPage, debouncedConnectionsSearchTerm]);

	useEffect(() => {
		axios.get(window.basePath + '/module').then((response) => {
			setModules((prevState) => {
				return {
					...prevState,
					modules: response.data.data,
				};
			});
		});
	}, []);

	const handleModuleSelectDialogOpen = () => {
		setIsModuleSelectDialogOpen(true);
	};

	const handleModuleSelectDialogClose = () => {
		setIsModuleSelectDialogOpen(false);

		setModules((prevState) => {
			return {
				...prevState,
				filteredModules: [],
			};
		});
		setSearchTerm(false);
	};

	const handleModuleListItemClick = (moduleCode) => () => {
		if (!moduleCode) {
			return;
		}

		handleModuleSelectDialogClose();

		createConnection({
			...getCommonSDK(),
			token: localStorage.getItem('token'),
			code: moduleCode,
		})
			.then((result) => {
				if (result) {
					loadConnections();
				}
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const handleModulesInputChange = (event) => {
		const searchTerm = event?.target?.value;

		if (searchTerm.length > 0) {
			setSearchTerm(true);
		} else {
			setSearchTerm(false);
		}

		const filteredModules = modules.filter((module) => {
			return module.name.toLowerCase().includes(searchTerm.toLowerCase());
		});
		setModules((prevState) => {
			return {
				...prevState,
				filteredModules,
			};
		});
	};

	const handleDeleteClick = (id) => () => {
		const result = window.confirm('Are you sure you want to remove the connection?');

		if (!result) {
			return;
		}

		axios
			.delete(`${window.basePath}/connection/${id}`)
			.then(() => {
				loadConnections();
			})
			.catch(() => {
				alert('Failed to delete connection. Please check if it is connected to a task.');
			});
	};

	const handleEditClick = (id) => () => {
		editConnection({
			...getCommonSDK(),
			token: localStorage.getItem('token'),
			connectionID: id,
		})
			.then((result) => {
				if (result) {
					loadConnections();
				}
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const handlePageChange = (newPage) => {
		setPage(newPage + 1);
	};

	return (
		<>
			<Grid container direction="column">
				<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
					<Typography variant="h4">Connections</Typography>
					<Button variant="contained" onClick={handleModuleSelectDialogOpen}>
						New connection
					</Button>
				</Grid>

				<Grid container sx={{ mb: 2 }}>
					<TextField
						label="Search"
						placeholder="Search by name"
						size="small"
						value={connectionsSearchTerm}
						onChange={(e) => {
							setPage(1);
							setConnectionsSearchTerm(e.target.value);
						}}
					/>
				</Grid>

				<Grid container sx={{ height: 370, width: '100%' }}>
					<DataGrid
						rows={connections}
						columns={columns}
						disableColumnMenu
						rowCount={total}
						pageSize={5}
						rowsPerPageOptions={[5]}
						page={page - 1}
						pagination
						loading={loading}
						paginationMode="server"
						onPageChange={handlePageChange}
					/>
				</Grid>
			</Grid>

			<Dialog open={isModuleSelectDialogOpen} onClose={handleModuleSelectDialogClose}>
				<DialogTitle>{'Which integration would you like to create?'}</DialogTitle>
				<TextField
					sx={{
						mx: 3,
					}}
					label="Search integrations"
					size="small"
					onChange={handleModulesInputChange}
				/>
				<DialogContent>
					<List>
						{(searchTerm ? filteredModules : modules)?.map((module) => (
							<ListItem key={module.code} button onClick={handleModuleListItemClick(module.code)}>
								<ListItemText>{module.name}</ListItemText>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleModuleSelectDialogClose}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Connections;
