import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
	Divider,
	Drawer,
	FormControl,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
} from '@mui/material';
import { Box } from '@mui/system';

const Auth = ({ setProject, history, children, project }) => {
	const drawerWidth = 240;

	const [companies, setCompanies] = useState([]);
	const [currentTeam, setCurrentTeam] = useState('');
	const [currentTeamID, setCurrentTeamID] = useState('');

	useEffect(() => {
		axios
			.post(window.basePath + '/auth/me')
			.then((response) => {
				localStorage.setItem('user', JSON.stringify(response.data));
				setCurrentTeam(response.data.currentTeam);
				setCurrentTeamID(response.data.currentTeam.id);
				setCompanies(response.data.teams);
			})
			.catch(() => {
				localStorage.removeItem('token');
				alert('Token not valid, please login again.');
				setTimeout(() => {
					history.push('/');
				});
			});
	}, [history, setProject]);

	const handleChangeCurrentCompany = (e) => {
		setCurrentTeamID(e.target.value);
		axios
			.post(window.basePath + '/company/current/' + e.target.value)
			.then(() => {
				history.push('/task');
			})
			.catch(() => {
				localStorage.removeItem('token');
				alert('Token not valid, please login again.');
				setTimeout(() => {
					history.push('/');
				});
			});
	};

	useEffect(() => {
		if (currentTeamID) {
			axios
				.get(window.basePath + '/project?company=' + currentTeamID)
				.then((response) => {
					setProject(response.data.data[0]);
				})
				.catch(() => {
					localStorage.removeItem('token');
					alert('Token not valid, please login again.');
					setTimeout(() => {
						history.push('/');
					});
				});
		}
	}, [currentTeamID, history, setProject]);

	const handleListItemClick = (route) => () => {
		if (route === 'logout') {
			window.localStorage.removeItem('token');
			setTimeout(() => {
				history.push('/');
			});
			return;
		}

		history.push(route);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<List>
					<ListItem>
						{companies?.length > 0 && (
							<FormControl sx={{ minWidth: 120 }} fullWidth size="small">
								<InputLabel>Company</InputLabel>
								<Select label="Company" onChange={handleChangeCurrentCompany} value={currentTeamID}>
									{companies.map((company) => {
										return (
											<MenuItem key={company.id} value={company.id}>
												{company.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						)}

						{!companies?.length && currentTeam?.name && <ListItemText>{currentTeam.name}</ListItemText>}
					</ListItem>

					<Divider />

					<ListItem button onClick={handleListItemClick('/connection')}>
						<ListItemText>Connections</ListItemText>
					</ListItem>

					<ListItem button onClick={handleListItemClick('/task')}>
						<ListItemText>Tasks</ListItemText>
					</ListItem>

					<Divider />

					<ListItem button onClick={handleListItemClick('logout')}>
						<ListItemText>Logout</ListItemText>
					</ListItem>
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				{children}
			</Box>
		</Box>
	);
};

export default withRouter(Auth);
