import { Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';

const CreateTask = ({ createSubmitTask, nextInProgress, currentTask }) => {
	const [val, setVal] = useState(currentTask?.name || '');
	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				createSubmitTask(val || currentTask?.name);
			}}
		>
			<Stack direction="column" spacing={2} sx={{ maxWidth: 320 }}>
				<TextField
					required
					label="Task name"
					placeholder="Please enter task name"
					size="small"
					value={val || currentTask?.name || ''}
					onChange={(e) => setVal(e.target.value)}
				/>

				<LoadingButton variant="contained" type="submit" loading={nextInProgress}>
					Next
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default CreateTask;
