import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Icon, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format, intervalToDuration } from 'date-fns';

const TaskExecutions = ({ history, match }) => {
	const [executions, setExecutions] = useState(null);
	const [notFound, setNotFound] = useState(false);

	const getExecutions = () => {
		const url = `${window.basePath}/execution/history/task/${match.params.taskID}`;

		console.log(url);

		axios
			.get(url)
			.then((response) => {
				setExecutions(response.data.data);
			})
			.catch(() => {
				setNotFound(true);
			});
	};

	useEffect(getExecutions, [history, match.params.taskID]);

	const intervalToString = (start, end) => {
		const interval = intervalToDuration({
			start,
			end,
		});

		const hours = `${interval.hours}`.padStart(2, '0');
		const minutes = `${interval.minutes}`.padStart(2, '0');
		const seconds = `${interval.seconds}`.padStart(2, '0');

		return `${hours}:${minutes}:${seconds}`;
	};

	const handleViewDetails = (executionID) => () => {
		history.push(`/task-logs/${executionID}`);
	};

	return (
		<Grid container direction="column">
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography variant="h4">Execution history</Typography>
			</Grid>

			{notFound && <Typography variant="p">Executions not found</Typography>}

			{executions && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Date & Time</TableCell>
							<TableCell>Trigger</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Duration</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{executions?.map((execution) => {
							return (
								<TableRow key={execution.id}>
									<TableCell>
										<Typography variant="p">{format(execution.triggeredAt * 1000, 'dd.MM.yyyy. HH:mm')}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="p">{execution.triggerName}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="p">{execution.status}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="p">
											{intervalToString(new Date(execution.startedAt * 1000), new Date(execution.endedAt * 1000))}
										</Typography>
									</TableCell>
									<TableCell>
										<IconButton component="span" onClick={handleViewDetails(execution.taskExecutionId)}>
											<Icon>visibility</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
		</Grid>
	);
};

export default TaskExecutions;
