import React from 'react';
import TaskList from './TaskList';
import { Button, Grid, Typography } from '@mui/material';

const Tasks = ({ project, history }) => {
	const handleNewTaskClick = () => {
		history.push('/task/new');
	};

	return (
		<Grid container direction="column">
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography variant="h4">Tasks</Typography>
				<Button variant="contained" onClick={handleNewTaskClick}>
					New task
				</Button>
			</Grid>

			<TaskList {...{ project, history }} />
		</Grid>
	);
};

export default Tasks;
