import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';

const TaskLogs = ({ history, match }) => {
	const [taskHistory, setTaskHistory] = useState(null);
	const [notFound, setNotFound] = useState(false);

	const getHistory = () => {
		axios
			.get(window.basePath + '/execution/history/progress/' + match.params.executionID)
			.then((response) => {
				setTaskHistory(response.data.data);
				if (response.data.data.status !== 'finished') {
					setTimeout(() => {
						getHistory();
					}, 5000);
				}
			})
			.catch(() => {
				setNotFound(true);
			});
	};

	useEffect(getHistory, [history, match.params.executionID]);

	return (
		<Grid container direction="column">
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography variant="h4">Task history</Typography>
			</Grid>

			{notFound && <Typography variant="p">Logs not found</Typography>}

			{taskHistory && (
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Description</TableCell>
							<TableCell>Level</TableCell>
							<TableCell>Title</TableCell>
							<TableCell>Time</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{taskHistory?.messages?.map((message, key) => {
							return (
								<TableRow key={key}>
									<TableCell>
										<Typography>{message.description}</Typography>
									</TableCell>
									<TableCell>{message.level}</TableCell>
									<TableCell>{message.title}</TableCell>
									<TableCell>{format(message.time, 'HH:mm:ss')}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
		</Grid>
	);
};

export default TaskLogs;
