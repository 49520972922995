import { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { blue } from '@mui/material/colors';

import Tasks from './pages/tasks/Task';
import Connections from './pages/connections/Connections';
import Auth from './pages/auth/Auth';
import Login from './pages/login/Login';
import CreateEditTask from './pages/create-edit-task/CreateEditTask';
import TaskLogs from './pages/task-logs/TaskLogs';
import TaskExecutions from './pages/task-executions/ExecutionHistory';

function App() {
	const [project, setProject] = useState(null);
	const theme = createTheme({
		status: {
			danger: blue[500],
		},
	});
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Login} />
					<Auth setProject={setProject} project={project}>
						{project && (
							<>
								<Route exact path="/task" component={(props) => <Tasks project={project} {...props} />} />
								<Route exact path="/task/:id" component={(props) => <CreateEditTask project={project} {...props} />} />
							</>
						)}
						<Route exact path="/task-executions/:taskID" component={TaskExecutions} />
						<Route exact path="/task-logs/:executionID" component={TaskLogs} />
						<Route exact path="/connection" component={Connections} />
					</Auth>
				</Switch>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
