import React, { useEffect } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { openSourceConfig, openDestinationConfig } from '@sync-spider/sdk';
import { getCommonSDK } from '../../../core/constants';

const Settings = ({ code, entity, handleSaveSettings, currentTask, direction, nextInProgress }) => {
	useEffect(() => {
		handleConfigureClick();
	});

	const handleConfigureClick = () => {
		const sdkShared = {
			...getCommonSDK(),
			projectID: currentTask.project,
			taskID: currentTask.id,
		};

		if (direction === 'source') {
			openSourceConfig({ ...sdkShared }).then(() => {});
		} else if (direction === 'destination') {
			const [firstDestination] = currentTask.destination;
			openDestinationConfig({
				...sdkShared,
				destinationID: firstDestination.id,
			}).then(() => {});
		}
	};

	return (
		<form onSubmit={handleSaveSettings}>
			<Stack spacing={2} style={{ maxWidth: 320 }}>
				<Typography variant="h5">Settings - {direction}</Typography>

				<Button onClick={handleConfigureClick}>Configure</Button>

				<LoadingButton variant="contained" type="submit" loading={nextInProgress}>
					Next
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default Settings;
