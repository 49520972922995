const { BASE_URL } = require('./constants');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XmlHttpRequest';
let token = localStorage.getItem('token');
if (token) {
	window.axios.defaults.headers.authorization = 'Bearer ' + token;
}
window.basePath = `${BASE_URL}/api/v1/agency`;
