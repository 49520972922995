import {
	Button,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';

const DisplayMapping = ({ history, currentTask }) => {
	return (
		<Grid container direction="column">
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography variant="h4">Preview mapping</Typography>
				<Button variant="outlined" onClick={() => history.push('/task')}>
					Back to tasks
				</Button>
			</Grid>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Source</TableCell>
							<TableCell>Destination</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{currentTask?.destination?.[0]?.mapping?.map((mapping, key) => (
							<TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{mapping.source.code}</TableCell>
								<TableCell>{mapping.target.code}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

export default DisplayMapping;
