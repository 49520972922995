import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Grid, Icon, IconButton, Menu, MenuItem } from '@mui/material';
import { useDebounce } from '../../utils/hooks';
import { DataGrid } from '@mui/x-data-grid';

const PER_PAGE = 5;

const TaskList = ({ history, project }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const debouncedPage = useDebounce(page, 500);
	const [total, setTotal] = useState(0);
	const [menuContext, setMenuContext] = useState({});
	const open = Boolean(anchorEl);

	const columns = [
		{ field: 'name', headerName: 'Name', sortable: false, flex: 1 },
		{
			field: 'id',
			headerName: '',
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<IconButton color="success" component="span" onClick={startTask(params.row)}>
							<Icon>play_arrow</Icon>
						</IconButton>

						<IconButton component="span" onClick={handleMenuClick(params.row)}>
							<Icon>more_vert</Icon>
						</IconButton>
					</>
				);
			},
		},
	];

	function getManyTasks(options) {
		const { perPage = PER_PAGE, page, search, company } = options || {};
		const baseURL = `${window.basePath}/synctask`;

		const queryParams = new URLSearchParams({
			...(company && { company }),
			...(perPage && { per_page: perPage }),
			...(page && { page }),
			...(search && { search }),
		}).toString();

		const url = `${baseURL}?${queryParams}`;

		return axios.get(url).then((response) => {
			return response.data;
		});
	}

	const handleMenuClick = (task) => (event) => {
		setAnchorEl(event.currentTarget);
		setMenuContext(task);
	};

	const loadTasks = () => {
		setLoading(true);
		getManyTasks({ page, company: project.company })
			.then(({ data, meta }) => {
				setTasks(data);
				setTotal(meta.pagination.total);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(loadTasks, [history, project, debouncedPage]);

	const startTask = (task) => async () => {
		try {
			const promises = [];

			task.destination.forEach((destination) => {
				const req = axios.put(`${window.basePath}/synctask/${task.id}/destination/${destination.id}`, { active: true });
				promises.push(req);
			});

			await Promise.all(promises);
			const res = await axios.post(`${window.basePath}/execution/start/${task.id}`);

			if (res?.data?.data?.executionId) {
				history.push(`/task-history/${res.data.data.executionId}`);
			} else {
				loadTasks();
			}
		} catch (error) {
			alert('Failed to start a task.');
		}
	};

	const handleMenuItemEditClick = (id) => () => {
		history.push(`/task/${id}`);
	};

	const handleMenuItemLogsClick = (id) => () => {
		history.push(`/task-executions/${id}`);
	};

	const handleMenuItemDeleteClick = (id) => () => {
		handleClose();

		const result = window.confirm('Are you sure you want to remove the task?');

		if (!result) {
			return;
		}

		axios
			.delete(`${window.basePath}/synctask/${id}`)
			.then(() => {
				loadTasks();
			})
			.catch(() => {
				alert('Failed to delete a task.');
			});
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handlePageChange = (newPage) => {
		setPage(newPage + 1);
	};

	return (
		<>
			<Grid container sx={{ height: 370, width: '100%' }}>
				<DataGrid
					rows={tasks}
					columns={columns}
					disableColumnMenu
					rowCount={total}
					pageSize={5}
					rowsPerPageOptions={[5]}
					pagination
					loading={loading}
					paginationMode="server"
					onPageChange={handlePageChange}
				/>
			</Grid>

			<Menu id="task-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuItem onClick={handleMenuItemLogsClick(menuContext.id)}>Logs</MenuItem>
				<MenuItem onClick={handleMenuItemEditClick(menuContext.id)}>Edit</MenuItem>
				<MenuItem onClick={handleMenuItemDeleteClick(menuContext.id)}>Delete</MenuItem>
			</Menu>
		</>
	);
};

export default withRouter(TaskList);
