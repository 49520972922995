import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const Login = ({ history }) => {
	const [loginInProgress, setLoginInProgress] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token) {
			history.push('/task');
		}
	}, [history]);

	const [{ email, emailError, password, passwordError }, setForm] = useState({
		email: '',
		emailError: false,
		password: '',
		passwordError: false,
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!email) {
			setForm((prevState) => {
				return { ...prevState, emailError: true };
			});
			return;
		}

		if (!password) {
			setForm((prevState) => {
				return { ...prevState, passwordError: true };
			});
			return;
		}

		setForm((prevState) => {
			return { ...prevState, emailError: false, passwordError: false };
		});

		setLoginInProgress(true);

		axios
			.post(window.basePath + '/auth/login', { email, password })
			.then((response) => {
				axios.defaults.headers.authorization = 'Bearer ' + response.data.access_token;
				localStorage.setItem('token', response.data.access_token);
				history.push('/task');
			})
			.catch((response) => {
				console.error(response);
			});
	};

	const handleInputChange = (name) => (event) => {
		setForm((prevState) => {
			const value = event?.target?.value;

			return {
				...prevState,
				[name]: value,
			};
		});
	};

	return (
		<Grid
			container
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}
		>
			<Card
				sx={{
					width: '100%',
					maxWidth: 500,
				}}
			>
				<CardContent>
					<Typography variant="h5" gutterBottom>
						SyncSpider
					</Typography>
					<Typography variant="p" gutterBottom>
						Please login to access demo environment
					</Typography>
					<form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
						<Stack spacing={2}>
							<TextField
								error={emailError}
								required
								label="Email"
								placeholder="Please enter you email"
								size="small"
								autoComplete="email"
								onChange={handleInputChange('email')}
							/>
							<TextField
								error={passwordError}
								required
								label="Password"
								type="password"
								placeholder="Please enter you password"
								size="small"
								autoComplete="current-password"
								onChange={handleInputChange('password')}
							/>

							<LoadingButton variant="contained" type="submit" loading={loginInProgress}>
								Login
							</LoadingButton>
						</Stack>
					</form>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default Login;
