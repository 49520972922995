import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CreateTask from './steps/CreateTask';
import AddSource from './steps/AddSource';
import Settings from './steps/Settings';
import AddDestination from './steps/AddDestination';
import SaveTemplate from './steps/SaveTemplate';
import DisplayMapping from './steps/DisplayMapping';
import { CircularProgress, Grid, Typography } from '@mui/material';

const CreateEditTask = ({ history, project, match }) => {
	const [connections, setConnections] = useState([]);
	const [connectionsLoading, setConnectionsLoading] = useState(true);
	const [currentTask, setCurrentTask] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [steps, setSteps] = useState(1);
	const [nextInProgress, setNextInProgress] = useState(false);
	const isCreate = match.params.id === 'new';

	useEffect(() => {
		setConnectionsLoading(true);

		axios
			.get(window.basePath + '/connection')
			.then((response) => {
				setConnections(response.data.data);
			})
			.catch(() => {})
			.finally(() => {
				setConnectionsLoading(false);
			});

		if (!isCreate) {
			axios
				.get(window.basePath + '/synctask/' + match.params.id)
				.then((response) => {
					setCurrentTask(response.data.data);
				})
				.catch(() => {});
		}
	}, [match, isCreate]);

	const createSubmitTask = (name) => {
		setNextInProgress(true);

		if (isCreate) {
			axios
				.post(window.basePath + '/synctask', { name, project: project.id })
				.then((response) => {
					setCurrentTask(response.data.data);
					setNextInProgress(false);
					setSteps(2);
				})
				.catch(() => {
					setNextInProgress(false);
				});
		} else {
			axios
				.put(window.basePath + '/synctask/' + currentTask.id, { name })
				.then((response) => {
					setCurrentTask(response.data.data);
					setNextInProgress(false);
					setSteps(2);
				})
				.catch(() => {
					setNextInProgress(false);
				});
		}
	};

	const addConnectionToProject = (connectionId) => {
		return axios.post(`${window.basePath}/project/${project.id}/connection`, { id: connectionId });
	};

	const handleSaveSource = ({ connectionId, entity }) => {
		setNextInProgress(true);

		addConnectionToProject(connectionId).then(() => {
			axios
				.put(window.basePath + '/synctask/' + currentTask.id, { source: { connectionId, entity } })
				.then((response) => {
					setCurrentTask(response.data.data);
					setSteps(3);
					setNextInProgress(false);
				})
				.catch(() => {
					setNextInProgress(false);
				});
		});
	};

	const handleSaveDestination = ({ connectionId, entity }) => {
		setNextInProgress(true);

		addConnectionToProject(connectionId).then(() => {
			const destinationID = currentTask?.destination?.[0]?.id;
			let url = window.basePath + '/synctask/' + currentTask.id + '/destination';
			let method = 'post';

			if (!isCreate && destinationID) {
				url += `/${destinationID}`;
				method = 'put';
			}

			axios[method](url, {
				connectionId,
				entity,
			})
				.then((response) => {
					axios.get(window.basePath + '/synctask/' + currentTask.id).then((response) => {
						const task = response.data.data;
						const sourceEntity = task?.source?.entity?.code;
						const sourceCode = task?.source?.connection?.moduleCode;
						const destinationEntity = task?.destination?.[0]?.entity?.code;
						const destinationCode = task?.destination?.[0]?.connection?.moduleCode;
						const query = `?sourceCode=${sourceCode}&destinationCode=${destinationCode}&sourceDestinationEntity=${sourceEntity}_${destinationEntity}`;

						setNextInProgress(false);
						setCurrentTask(task);

						axios.get(window.basePath + '/mapping-template' + query).then((response) => {
							setTemplates(response.data.data);
						});

						if (task?.source?.entity?.hasDynamicConfiguration) {
							setSteps(4);
							return;
						} else if (task?.destination?.[0]?.entity?.hasDynamicConfiguration) {
							setSteps(5);
							return;
						} else {
							setSteps(6);
							return;
						}
					});
				})
				.catch(() => {
					setNextInProgress(false);
				});
		});
	};

	const handleSaveMapping = ({ templateId }) => {
		setNextInProgress(true);
		axios
			.post(
				window.basePath +
					'/synctask/' +
					currentTask.id +
					'/destination/' +
					currentTask.destination[0].id +
					'/mapping/' +
					templateId +
					'/auto',
			)
			.then((response) => {
				axios.get(window.basePath + '/synctask/' + currentTask.id).then((response) => {
					setCurrentTask(response.data.data);
					setNextInProgress(false);
					setSteps(7);
				});
			})
			.catch(() => {
				setNextInProgress(false);
			});
	};

	const handleSaveSettings = (e) => {
		e.preventDefault();

		if (steps < 5 && currentTask?.destination?.[0]?.entity?.hasDynamicConfiguration) {
			setSteps(5);
			return;
		} else {
			setSteps(6);
			return;
		}
	};

	return (
		<Grid container direction="column">
			<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Typography variant="h4">New Task</Typography>
			</Grid>

			{!connectionsLoading && (
				<>
					{currentTask && (
						<Grid container direction="column" sx={{ mb: 4 }}>
							<Typography variant="p">
								Task: <b>{currentTask.name}</b>
							</Typography>
							<Typography variant="p">
								Source: <b>{currentTask?.source?.connection?.name}</b>
							</Typography>

							<Typography variant="p">
								Destination: <b>{currentTask?.destination?.[0]?.connection?.name}</b>
							</Typography>
						</Grid>
					)}

					{steps === 1 && (
						<CreateTask createSubmitTask={createSubmitTask} currentTask={currentTask} nextInProgress={nextInProgress} />
					)}

					{steps === 2 && (
						<AddSource
							connections={connections}
							handleSaveSource={handleSaveSource}
							currentTask={currentTask}
							nextInProgress={nextInProgress}
						/>
					)}

					{steps === 3 && (
						<AddDestination
							connections={connections}
							handleSaveDestination={handleSaveDestination}
							currentTask={currentTask}
							nextInProgress={nextInProgress}
						/>
					)}

					{steps === 4 && (
						<Settings
							handleSaveSettings={handleSaveSettings}
							connection={currentTask?.source?.connection}
							code={currentTask?.source?.connection?.moduleCode}
							entity={currentTask?.source?.entity?.code}
							currentTask={currentTask}
							direction="source"
							nextInProgress={nextInProgress}
						/>
					)}

					{steps === 5 && (
						<Settings
							handleSaveSettings={handleSaveSettings}
							code={currentTask?.destination?.[0]?.connection?.moduleCode}
							entity={currentTask?.destination?.[0]?.entity?.code}
							currentTask={currentTask}
							direction="destination"
							nextInProgress={nextInProgress}
						/>
					)}
					{steps === 6 && (
						<SaveTemplate handleSaveMapping={handleSaveMapping} templates={templates} nextInProgress={nextInProgress} />
					)}
					{steps === 7 && (
						<DisplayMapping currentTask={currentTask} history={history} nextInProgress={nextInProgress} />
					)}
				</>
			)}

			{connectionsLoading && (
				<Grid container direction="row" sx={{ mt: 4, justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress />
				</Grid>
			)}
		</Grid>
	);
};

export default CreateEditTask;
