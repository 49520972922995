import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

const SaveTemplate = ({ handleSaveMapping, templates, nextInProgress }) => {
	const [templateId, setTemplate] = useState('');

	const handleTemplateChange = (e) => {
		const value = Number(e.target.value);

		setTemplate(value);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				handleSaveMapping({ templateId });
			}}
		>
			<Stack spacing={2} style={{ maxWidth: 320 }}>
				<Typography variant="h5">Select mapping template</Typography>

				<FormControl fullWidth size="small">
					<InputLabel>Template</InputLabel>
					<Select label="Template" value={templateId} onChange={handleTemplateChange}>
						{templates.map((template, key) => {
							return (
								<MenuItem key={key} value={template.id}>
									{template.name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				{templateId && (
					<LoadingButton variant="contained" type="submit" loading={nextInProgress}>
						Next
					</LoadingButton>
				)}
			</Stack>
		</form>
	);
};

export default SaveTemplate;
