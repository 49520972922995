import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
	Box,
	TextField,
	Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const AddDestination = ({ handleSaveDestination, connections, nextInProgress, currentTask }) => {
	const [connection, setConnection] = useState('');
	const [entity, setEntity] = useState('');
	const [entities, setEntities] = useState([]);

	useEffect(() => {
		const taskConnection = currentTask?.destination?.[0]?.connection;

		if (!taskConnection) {
			return;
		}

		const connection = connections.find((connection) => {
			return Number(connection.id) === taskConnection.id;
		});

		if (connection?.moduleCode) {
			const entityCode = currentTask?.destination?.[0]?.entity?.code;

			setConnection(taskConnection);

			if (entityCode) {
				setEntity(entityCode);
			}

			axios
				.get(`${window.basePath}/module/${connection?.moduleCode}/entities?direction=export`)
				.then((response) => {
					setEntities(response.data.data);
				})
				.catch((response) => {
					console.log(response.error);
				});
		}
	}, [currentTask, connections]);

	const handleConnectionChange = (selectedConnection) => {
		if (!selectedConnection) {
			setEntities([]);
			return;
		}

		setConnection(selectedConnection);

		const connection = connections.find((connection) => {
			return Number(connection.id) === selectedConnection.id;
		});

		if (connection?.moduleCode) {
			axios
				.get(`${window.basePath}/module/${connection?.moduleCode}/entities?direction=export`)
				.then((response) => {
					setEntities(response.data.data);
				})
				.catch((response) => {
					console.log(response.error);
				});
		}
	};

	const handleEntityChange = (e) => {
		const value = e.target.value;
		setEntity(value);
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();

				if (!connection) {
					alert('You need to select destination');
					return;
				}

				if (!!entities.length && !entity) {
					alert('You need to select entity');
					return;
				}

				handleSaveDestination({ connectionId: connection.id, entity });
			}}
		>
			<Stack spacing={2} style={{ maxWidth: 320 }}>
				<Typography variant="h5">Add destination</Typography>

				{!!connections.length && (
					<Autocomplete
						options={connections}
						size="small"
						value={connection}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						getOptionLabel={(option) => {
							return option?.name || '';
						}}
						onChange={(event, newValue) => {
							handleConnectionChange(newValue);
						}}
						renderOption={(props, option) => {
							return (
								<Box component="li" {...props} key={option.id}>
									{option.name}
								</Box>
							);
						}}
						renderInput={(params) => {
							return <TextField {...params} placeholder="Destination" />;
						}}
					/>
				)}

				{!!entities.length && (
					<FormControl fullWidth size="small">
						<InputLabel>Entity</InputLabel>
						<Select label="Entity" value={entity} onChange={handleEntityChange}>
							{entities.map((entity, key) => {
								return (
									<MenuItem key={key} value={entity.code}>
										{entity.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				)}

				{entity && (
					<LoadingButton variant="contained" type="submit" loading={nextInProgress}>
						Next
					</LoadingButton>
				)}
			</Stack>
		</form>
	);
};

export default AddDestination;
