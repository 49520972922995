export const BASE_URL = process.env.REACT_APP_BASE_URL;

export function getCommonSDK() {
	return {
		token: localStorage.getItem('token'),
		colorPrimary: 'ff632e',
		colorPrimaryHover: 'ff8f66',
		baseURL: BASE_URL,
	};
}
